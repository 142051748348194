import { createGlobalStyle } from 'styled-components'

import VT323 from './fonts/upheavtt.ttf';
// import Gumball from './Gumball.ttf';

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=VT323');



@font-face {
  font-family: "VT323";
  src: local("VT323"), url(https://fonts.googleapis.com/css?family=VT323) ;
}
`;

// export const Renomono = createGlobalStyle`
//     @font-face {
//         font-family: 'Renomono';
//         src: url(${RenoMono}) format('opentype');
//     }
// `;

export default GlobalStyle;