import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Web3 from "web3";
import Navigation from "./Navigation";
import 'rsuite/styles/index.less';
import "rsuite/dist/rsuite.min.css";
import { Panel, PanelGroup } from 'rsuite';
import { Carousel } from 'rsuite';
import { Notification, toaster } from 'rsuite';
import { Loader } from 'rsuite';
import { Badge } from 'rsuite';
import Alert from "../src/Alert";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
display: inline-block;
background-color: red;
font: "Josefin Sans";
color: ${props => props.theme.body};
outline: none;
border: none;

font-size: ${props => props.theme.fontsm};
padding: 0.9rem 2.3rem;
border-radius: 50px;
cursor: pointer;
transition: all 0.2s ease;
position: relative;
align-items: center;
justify-content: center;
align-content: center;
&:hover{
    transform: scale(0.9);
}

&::after{
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    border: 2px solid ${props => props.theme.text};
    width: 100%;
    height: 100%;
    border-radius: 50px;
    transition: all 0.2s ease;
}

&:hover::after{
    transform: translate(-50%, -50%) scale(1);
    padding: 0.3rem;
}
transition: width 0.5s;
`

export const TextTitle = styled.p`
  font-family: 'VT323';
  color: black;
  font-size: 20px;
  font-weight: 400;

  line-height: 1.6;
  text-align: center;
  @media (max-width: 565px) {
    font-size: 25px;
    text-align: center;
    letter-spacing: 2px;
  }
  transition: width 0.5s;;
`;

export const TextTitlee = styled.p`
  font-family: 'VT323';
  color: black;
  font-size: 20px;
  font-weight: 400;

  line-height: 1.6;
  text-align: center;

  @media (max-width: 89em){
    font-size: 25px;
    text-align: center;
    letter-spacing: 2px;
   
  }
  
  @media (max-width: 80em){
    font-size: 20px;
    text-align: center;
    letter-spacing: 2px;
   
  }
  
  @media (max-width: 70em){
    font-size: 20px;
    text-align: center;
    letter-spacing: 2px; 
 
  }
  
  @media (max-width: 60em){
    font-size: 20px;
    text-align: center;
    letter-spacing: 2px; 
 
  }
  
  @media (max-width: 50em){
    font-size: 20px;
    text-align: center;
    letter-spacing: 2px; 
  
  }
  @media (max-width: 40em){
    font-size: 15px;
    text-align: center;
    letter-spacing: 2px; 
  
  
  }
  @media (max-width: 30em){
    font-size: 15px;
    text-align: center;
    letter-spacing: 2px; 
   }
  

   transition: width 0.5s;
  
`;


export const CTNButton = styled.button`
display: inline-block;
background-color: white;
font: "Josefin Sans";
color: ${props => props.theme.body};
outline: none;
border: black solid;


font-size: ${props => props.theme.fontsm};
padding: 0.9rem 2.3rem;
border-radius: 5px;
cursor: pointer;
transition: all 0.2s ease;
position: relative;
&:hover{
    transform: scale(0.9);
}

&::after{
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    border: 2px solid blue;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    transition: all 0.2s ease;
}

&:hover::after{
    transform: translate(-50%, -50%) scale(1);
    padding: 0.3rem;
}
transition: width 0.5s;
`

export const Maxbtn = styled.button`
display: inline-block;
background-color: black;
font: "Josefin Sans";
color: ${props => props.theme.body};
outline: none;
width: 50%;
border: none;
align-items: center;
justify-content: center;
align-content: center;

font-size: ${props => props.theme.fontsm};
padding: 0.9rem 2.3rem;
border-radius: 50px;
cursor: pointer;
transition: all 0.2s ease;
position: relative;
&:hover{
    transform: scale(0.9);
}

&::after{
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    border: 2px solid ${props => props.theme.text};
    width: 100%;
    height: 100%;
    border-radius: 50px;
    transition: all 0.2s ease;
}

&:hover::after{
    transform: translate(-50%, -50%) scale(1);
    padding: 0.3rem;
}
`

export const StyledRoundButton = styled.button`
display: inline-block;
background-color: red;
font: "Josefin Sans";
color: ${props => props.theme.body};
outline: none;
border: none;
align-items: center;
justify-content: center;
align-content: center;
font-size: ${props => props.theme.fontsm};
padding: 0.9rem 2.3rem;
border-radius: 50px;
cursor: pointer;
transition: all 0.2s ease;
position: relative;
&:hover{
    transform: scale(0.9);
}

&::after{
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    border: 2px solid ${props => props.theme.text};
    width: 100%;
    height: 100%;
    border-radius: 50px;
    transition: all 0.2s ease;
}

&:hover::after{
    transform: translate(-50%, -50%) scale(1);
    padding: 0.3rem;
}
`

export const StyledImg = styled.img`
  box-shadow: 0px 3px 11px 1px rgba(0, 0, 0, 2);
  border: 4px solid black;
  background-color: var(--accent);
  border-radius: 10px;
  width: 180px;
  flex-direction: column;

  @media (min-width: 700px) {
    width: 160px;
  }

  @media (min-width: 900px) {
    width: 150px;
  }

  @media (min-width: 1000px) {
    width: 200px;
  }

  @media (min-width: 1300px) {
    width: 200px;
  }

  @media (min-width: 1500px) {
    width: 250px;
  }
  transition: width 0.5s;
`;


export const LogoDiv = styled.div`
display: flex;
align-items: center;
justify-content: center;
align-content: center;
gap: 10%;
width: 300px;
`;


export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;


export const ResponsiveWrapperr = styled.div`
background: beige;
padding: 30;
flex-direction: center;
align-items: center;
justify-content: center;
align-content: center;


border: 40px;
border: solid;
border-radius: 30px;
boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)";
 
  @media (min-width: 367px) {
   flex-direction: row;
    
  }
`;

export const ResponsiveWrapperHeader = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 80px;
  
  padding: 10px;
  background-color : beige;
  @media (min-width: 767px) {
    flex-direction: row;
  }
  @media (max-width: 565px) {
    max-height: 220px;
  }
`;

export const StyledLogo = styled.img`
  display: inline;
  width: 650px;
  @media (max-width: 1300px) {
    width: 500px;
    transition: width 0.5s;
    transition: height 0.5s;
  }

  @media (max-width: 1000px) {
    width: 500px;
    transition: width 0.5s;
    transition: height 0.5s;
  }
  @media (max-width: 767px) {
    width: 450px;
    transition: width 0.5s;
    transition: height 0.5s;
  }

  @media (max-width: 567px) {
    width: 350px;
    transition: width 0.5s;
    transition: height 0.5s;
  }
  transition: width 0.5s;
`;







export const Styledroad = styled.img`
  width: 100%;
  border-radius: 5px;
  transition: width 0.5s;
`;

export const StyledImgSmall = styled.img`
  width: 220px;
  height: 220px;
  border-radius: 5px;
  @media (min-width: 900px) {
    width: 220px;
    height: 220px;
  }
  @media (min-width: 1000px) {
    width: 220px;
    height: 220px;
  }
  transition: width 0.5s;
  @media (max-width: 565px) {
    width: 200px;
  }
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

export const WalletBox = styled.div`
  text-decoration: none;
  border-radius: 5px;
  border: 2px solid black;
  background-color: White;
  //padding: 10px;
  font-weight: bold;
  font-size: 15px;
  width: 180px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 0px 1px black;
  -webkit-box-shadow: 0px 1x 0px 1px black;
  -moz-box-shadow: 0px 1x 0px 1px  black;
  @media (max-width: 565px) {
    margin-top: 20px;
  
`;

export const StyledLogoo = styled.img`
  display: inline;
  width: 450px;

  @media (max-width: 89em){
    width: 450px; 
   
  }
  
  @media (max-width: 80em){
    width: 400px; 
   
  }
  
  @media (max-width: 70em){
    width: 340px; 
 
  }
  
  @media (max-width: 60em){
    width: 300px; 
 
  }
  
  @media (max-width: 50em){
    width: 300px; 
  
  }
  @media (max-width: 40em){
    width: 300px; 
  
  
  }
  @media (max-width: 30em){
    width: 300px; 
   }
  
  @media (max-width: 767px) {
    width: 280px;
   
  }

  @media (max-width: 567px) {
    width: 250px;
  
  }
  transition: width 0.5s;
`;


export const StyledLogooo = styled.img`
  display: inline;
  width: 800px;

  @media (max-width: 89em){
    width: 750px; 
   
  }
  
  @media (max-width: 80em){
    width: 700px; 
   
  }
  
  @media (max-width: 70em){
    width: 600px; 
 
  }
  
  @media (max-width: 60em){
    width: 600px; 
 
  }
  
  @media (max-width: 50em){
    width: 600px; 
  
  }
  @media (max-width: 40em){
    width: 600px; 
  
  
  }
  @media (max-width: 30em){
    width: 600px; 
   }
  
  @media (max-width: 767px) {
    width: 500px;
   
  }

  @media (max-width: 567px) {
    width: 370px;
    height: 88px;
  
  }
  transition: width 0.5s;
`;

export const FAQ = styled.img`
  display: inline;
  width: 150px;

  @media (max-width: 89em){
    width: 150px; 
   
  }
  
  @media (max-width: 80em){
    width: 150px; 
   
  }
  
  @media (max-width: 70em){
    width: 150px; 
 
  }
  
  @media (max-width: 60em){
    width: 150px; 
 
  }
  
  @media (max-width: 50em){
    width: 150px; 
  
  }
  @media (max-width: 40em){
    width: 130px; 
  
  
  }
  @media (max-width: 30em){
    width: 120px; 
   }

  }
  transition: width 0.5s;
`;

export const NavText = styled.p`
  font-family: 'VT323';
  color: var(--primary-text);
  text-decoration: none;
  font-size: 25px;
  margin-right: 5rem;
  text-align: center;
  cursor: pointer;
  :hover {
    color: #FF8938;
  }
  @media (max-width: 565px) {
  
  }
  @media (max-width: 565px) {
    font-size: 25px;
    text-align: center;
    letter-spacing: 2px;
  }

  @media (max-width: 64em) {
    /* 1024 px */

    font-size: 0px;
    
    
    
  
    
    
    }
  transition: width 0.5s;;
`;



const Figure = styled.div`
width: 510px; 
height: 400px;
box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 2);


background-color: beige;
border-radius: 5px;
flex-direction: center;
align-items: center;
justify-content: center;
align-content: center;
display: flex;
flex-direction: column;
  justify-content: center



@media (max-width: 89em){
  width: 500px; 
  height: 400px;
}

@media (max-width: 80em){
  width: 450px; 
  height: 390px;
}

@media (max-width: 70em){
  width: 370px; 
  height: 350px;
}

@media (max-width: 60em){
  width: 350px; 
  height: 320px;
}

@media (max-width: 50em){
  width: 350px; 
  height: 320px;
  flex-direction: column;
  justify-content: center
}
@media (max-width: 40em){
  width: 300px; 
  height: 320px;
  flex-direction: column;
  justify-content: center

}
@media (max-width: 30em){
  width: 300px; 
  height: 320px;
  flex-direction: column;
  justify-content: center

}

transition: width 0.5s;



`

const Article = styled.div`
display: flex;
width
flex-flow: row wrap;
margin-bottom: 4em;


@media (max-width: 89em){

}

@media (max-width: 80em){

}

@media (max-width: 70em){
 
}

@media (max-width: 60em){
 
}

@media (max-width: 65em){
  
  display: flex;
  flex-direction: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 59em){

  flex-direction: column;
  justify-content: center;

}
@media (max-width: 30em){

  flex-direction: column;
  justify-content: center;


}




`



const Section = styled.section`

position:fixed;
z-index:1000;




height: 60p;




padding: 0.9rem 2.3rem;




`

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
 
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [tokens, settokens] = useState(1);
  const [brd, setbrd] = useState("2px solid #FFFFFF");
  const [bxsh, setbxsh] = useState("0px 0px 3px 0px #FFFFFF");
  const [DOT, setDOT] = useState("red");
  const [type, setType] = React.useState('info');
  const [placement, setPlacement] = React.useState('topStart');
  const errmessage = (
    <Notification type={'error'} header={'error'} closable>
     Sorry, something went wrong please try again later.
    </Notification>
  );
  const txmessage = (
    <Notification type={'success'} header={'success'} closable>
     Congrats, Mint Was successfull.
    </Notification>
  );
  const mntmessage = (
    <Notification type={'info'} header={'success'} closable>
     <Loader/> Minting in Progress....
    </Notification>
  );
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    DISPLAY_COST: 0,
    WL_Display: 0,
    GAS_LIMIT: 0,
    MAX_PER_TX: 0,

    
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    Telegram: "",
    Discord: "",
    Twitter: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {

    let cost = CONFIG.DISPLAY_COST * tokens;
    ///let cost = (CONFIG.freetokens - tokens) * CONFIG.DISPLAY_COST
    
    let price = Web3.utils.toWei(cost.toString(), 'ether');
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    console.log("Cost: ", price);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    setbrd("2px solid yellow");
    setbxsh("0px 0px 3px 0px yellow");
    toaster.push(mntmessage, { placement })
    blockchain.smartContract.methods
      .mint(tokens)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: price,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
        toaster.push(errmessage, { placement })
        setbrd("2px solid red");
        setbxsh("0px 0px 3px 0px red");
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        toaster.push(txmessage, { placement })
        setbrd("2px solid green");
        setbxsh("0px 0px 3px 0px green");
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementtokens = () => {
    let newtokens = tokens - 1;
    if (newtokens < 1) {
      newtokens = 1;
    }
    settokens(newtokens);
  };

  const incrementtokens = () => {
    let newtokens = tokens + 1;
    if (newtokens > CONFIG.MAX_PER_TX) {
      newtokens = CONFIG.MAX_PER_TX;
    }
    settokens(newtokens);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      setAddress(blockchain.account.substring(0,4) + "..." + blockchain.account.substring(38,42));
      setDOT("green");
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      
   



     
         
        <s.Container flex={2} jc={"center"} ai={"center"}   style={{backgroundColor: "beige" }}  >

       <Navigation/>
 
         
    

        <s.SpacerLarge />
        <s.SpacerSmall/>
    
      
        <LogoDiv>
        
        
            <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
         
         
          </LogoDiv>
            <s.SpacerXSmall />

          <s.Headerlinks>
            
            <NavText href="#sneak">
                
              </NavText>
              <NavText href="#faq">
               Mint
              </NavText>
              <NavText href="#faq">
               About
              </NavText>
              <NavText href="#faq">
               FAQ
              </NavText>
              <NavText href="#sneak">
              
              </NavText>
          </s.Headerlinks>
          <s.SpacerXSmall />


          <s.HeaderDiv>
            <s.Spacerr/>
         
          
          <s.socialDiv>
            
       
            <a href={CONFIG.Twitter} target={"_blank"}>
          <s.Icons src="/config/images/twitter.svg" alt="twitter" />
          </a>
      
          <a href={CONFIG.MARKETPLACE_LINK} target={"_blank"}>
          <s.Icons src="/config/images/opensea.svg" alt="opensea" />
          </a>
          </s.socialDiv>
         
          </s.HeaderDiv>
          <s.SpacerLarge />
          
          </s.Container>
          
          
          

          


          
         
          <s.Container
        flex={1}
         ai={"center"}
        style={{backgroundColor: "lightblue" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        <s.SpacerLarge />

        <div className="App">
      <Alert type="error" message="Ensure free mint is claimed first" />
    
     
      </div>
       



          <ResponsiveWrapper flex={1} style={{ padding: 40 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/13.jpg"} />
          </s.Container>
          
          <s.SpacerLarge />
         
     

        
       
          <s.SpacerLarge />
      
        <s.SpacerSmall/>
        <Article id="Mint">
        <Figure>
            



           {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
              
              </>
            ) : (
              <>
             

                        <LogoDiv>
       
       
            <StyledLogoo alt={"logo"} src={"/config/images/mintlive.png"} />
        
        
          </LogoDiv>

          <s.SpacerLarge />
            
   
                    <s.SpacerSmall />
                    <a href="https://free.m00se.xyz/" target={"_blank"}>
                    <CTNButton
                     
                    >
                      Free mint 
                 
                    </CTNButton>
                    </a>
                    <s.SpacerSmall />
                    <a href="https://mint.m00se.xyz/" target={"_blank"}>
                    <CTNButton
                    
                    >
                      Public Mint
                      
                    </CTNButton>
                    </a>
                <s.SpacerMedium/>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <>
                  <s.Container ai={"center"} jc={"center"}>
                    <s.SpacerSmall />
                    
                   
                      <>
                       
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                            fontFamily: "VT323",
                            
                          }}
                        > Click selected box to mint
                         
                        </s.TextDescription>
                      </>
                   
                  </s.Container>
                  </>
                ) : (
                  <>
                    <s.AmountContainer style={{
                      border: brd,
                      boxShadow: bxsh,
                    }}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementtokens();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.TEXTamount>
                        &ensp;&ensp;&ensp;&ensp;{tokens}&ensp;&ensp;&ensp;&ensp;
                      </s.TEXTamount>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementtokens();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.AmountContainer>
                    <s.SpacerSmall />
                    <Maxbtn
                        onClick={(e) => {
                          e.preventDefault();
                          settokens(CONFIG.MAX_PER_TX);
                        }}
                        >
                      SetMax
                    </Maxbtn>
                    <s.SpacerSmall />
                    <s.SpacerSmall />
                    <s.TextTotal style={{color: "black"}}>
                      Total&emsp;&emsp;&emsp;&emsp;&emsp;{(CONFIG.DISPLAY_COST * tokens).toString().substring(0, 6)}{" "}{CONFIG.NETWORK.SYMBOL}
                    </s.TextTotal>
                    <s.SpacerSmall />
                    <s.SpacerXSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"column"}>
                            <StyledButton
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              claimNFTs();
                              getData();
                            }}
                          >
                            {claimingNft ? <Loader speed="fast" content="Minting..." /> : "MINT"} 
                          </StyledButton>
                    </s.Container>
                    <s.SpacerXSmall/>
                    <s.TextSubTitle style={{fontSize: 15}}>
                    Max {CONFIG.MAX_PER_TX} Per Tx
                    </s.TextSubTitle>
                    <s.SpacerXSmall/>
                    <s.TextSubTitle style={{textAlign: "center", fontSize: "1rem"}}>
                    {feedback}
                    </s.TextSubTitle>
              </>
            )}
            </>
            )}
            </Figure>
            </Article>
            <s.SpacerLarge />
         
     

        
       
          <s.SpacerLarge />
      
        <s.SpacerSmall/>
          
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"example"}
              src={"/config/images/OG.jpg"}
              style={{ transform: "scaleX(-1)" }}
            />
          </s.Container>


          <s.Container/>
        </ResponsiveWrapper>
      
       


        <s.SpacerLarge />
        
        <s.SecContainer flex={1}
         ai={"center"}
        style={{backgroundColor: "beige" }} id="About">
          <s.SpacerXSmall/>
    
          <LogoDiv>
        
        
            <StyledLogooo alt={"logo"} src={"/config/images/welcome.png"} />
   
   
          </LogoDiv>
       
            <s.TextP>
            <TextTitlee>
            The m00n m00se is a collection of 4444 unique NFTs collectibles, inspired by the famous MoonBirds and y00ts.
            </TextTitlee>
            <TextTitlee>
m00n m00se pays tribute to the OG MoonBirds by matching PixelArt. Guess why? Y00ts on eth can only m00n!
</TextTitlee>        
</s.TextP>

<s.SpacerLarge/>
<s.SpacerLarge/>
            </s.SecContainer>

            
            
              <s.SecContainer id="Faq">
              <LogoDiv>
        
        
            <FAQ alt={"logo"} src={"/config/images/faq.png"} />
     
     
          </LogoDiv>
            <s.SpacerLarge/>
            <PanelGroup style={{width: "80%", borderColor: "black", color: "black" }} accordion bordered>
    <Panel  header="How to buy a NFT?" >
    <s.TextP style={{textAlign: "left"}}>
          


Purchase Ethereum from various exchanges, such as Coinbase or Binance - Send Ethereum from this exchange to your MetaMask wallet - When it is time to mint, open the website and select the number of NFTs you wish to purchase - Click "MINT" button, Metamask will popup asking for connection - Confirm the transaction and any associated fees.
          </s.TextP>
    </Panel>
    
    
    <Panel header="Where can i view my NFTs?">
    <s.TextP style={{textAlign: "left"}}>
    Once minted or bought, simply connect to your OpenSea account to view your NFTs
          </s.TextP>
    </Panel>
    <Panel header="How many NFTs are there?">
    <s.TextP style={{textAlign: "left"}}>
    A total of 4444 unique m00n m00se NFTs will be created.
          </s.TextP>
    </Panel>
    <Panel header="What are we working next?">
    <s.TextP style={{textAlign: "left"}}>
    We continue to grow our community, oganize giveaways and promotions on social networks. We also start working on rewards for holders. Airdrop NFT Collection already in production
          </s.TextP>
    </Panel>
    
  </PanelGroup>
            </s.SecContainer>



            <s.SecContainer flex={1}
         ai={"center"}
        style={{backgroundColor: "beige" }}id="">
                
          <s.SpacerLarge/>
          <s.SpacerMedium />
          <s.TextPP>
          Copyright © 2022 m00n m00se
          </s.TextPP>
          <s.SpacerMedium />
          <s.SpacerMedium />
          

            </s.SecContainer>




        
      </s.Container>
    </s.Screen>
  );
}

export default App;
