import React from 'react'
import styled from 'styled-components'




const Buttt = styled.button`
display: inline-block;
background-color: lightblue;
color: black;
outline: lightblue;
border: lined;
font-family: "AquireRegular";


font-size: ${props => props.theme.fontsm};
padding: 0.9rem 2.3rem;

cursor: pointer;
transition: all 0.2s ease;
position: relative;
&:hover{
    transform: scale(0.9);
}

&::after{
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    border: 2px solid lightblue;
    width: 100%;
    height: 100%;
    border-radius: px;
    transition: all 0.2s ease;
}

&:hover::after{
    transform: translate(-50%, -50%) scale(1);
    padding: 0.3rem;
}
`

const Butt = ({text, link}) => {
  return (
<a href={link} aria-label={text} target="_blank" rel="noreferrer" >
<Buttt>{text}</Buttt>
</a>
    
  )
}

export default Butt